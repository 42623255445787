import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from  'rxjs/operators';
import { LatLng } from '@ionic-native/google-maps';
import { Employee } from '../auth/user';
import { Member } from '../auth/user';
import { Project } from '../auth/user';
import { GangProject } from '../auth/user';

@Injectable({
  providedIn: 'root'
})

export class RemoteDataService {

  private employeeLatLng: LatLng;
  private employeeData: Employee;
  private memberData: Member = null;
  private project: GangProject = null;

  constructor(private httpClient: HttpClient) {
  }

  saveEmployeeData(): any {
    return this.httpClient.post('/set-password', null).pipe(
      tap(async (res: any) => {

      })
    );
  }

  getEmployeeData(): any {
    return this.httpClient.get('/get-employee-data').pipe(
      tap(async (res: any) => {
        if(res.success)
          this.employeeData = res.data;
        else
          this.employeeData = null;
      })
    );
  }

  getLocalEmployeeData(): Employee {
    return this.employeeData;
  }

  getGangEmployeesData(): any {
    return this.httpClient.get('/get-gang-employees-data').pipe(
      tap(async (res: any) => {

      })
    );
  }

  getActiveProjects(): any {
    return this.httpClient.get('/get-active-projects').pipe(
      tap(async (res: any) => {

      })
    );
  }

  setGangActiveProject(project: string): any {
    return this.httpClient.post('/set-gang-active-project', {obr_IdObra: project}).pipe(
      tap(async (res: any) => {

      })
    );
  }

  setGangMemberActiveProject(member: Member, obr_IdObra: string): any {
    return this.httpClient.post('/set-gang-member-active-project', {per_IdPersonal: member.per_IdPersonal, obr_IdObra: obr_IdObra}).pipe(
      tap(async (res: any) => {

      })
    );
  }

  setEmployeeActiveProject(employee: Employee, obr_IdObra: string): any {
    return this.httpClient.post('/set-employee-active-project', {per_IdPersonal: employee.per_IdPersonal, obr_IdObra: obr_IdObra}).pipe(
      tap(async (res: any) => {

      })
    );
  }

  setEmployeeImage(employee: Employee, image: string): any {
    return this.httpClient.post('/set-employee-image', {per_IdPersonal: employee.per_IdPersonal, image: image}).pipe(
      tap(async (res: any) => {

      })
    );
  }

  setProject(project: GangProject) {
    this.project = project;
  }

  getProject():GangProject {
    return this.project;
  }

  setCheckInTime(data:any): any {
    return this.httpClient.post('/set-checkin-time', data).pipe(
      tap(async (res: any) => {

      })
    );
  }
  
  setCheckOutTime(data:any): any {
    return this.httpClient.post('/set-checkout-time', data).pipe(
      tap(async (res: any) => {

      })
    );
  }
  
  checkPinCode(per_IdPersonal: string, pincode: string) : any {
    return this.httpClient.post('/check-pin-code', {per_IdPersonal: per_IdPersonal, pincode: pincode}).pipe(
      tap(async (res: any) => {

      })
    );
  }

  setGangMemberData(member: Member) {
    this.memberData = member;
  }

  getGangMemberData() {
    return this.memberData;
  }

  selectedMembers: Object = new Object();
  addSelectedMember(member: Member) {
    this.selectedMembers[member.per_IdPersonal] = member;
  }

  removeSelectedMember(member: Member) {
    delete this.selectedMembers[member.per_IdPersonal];
  }

  clearSelectedMembers() {
    this.selectedMembers = new Object();
  }

  getSelectedMembers() {
    return this.selectedMembers;
  }

  isThereSelectedMembers() : boolean {
    var isThereAny = false;
    for(var per_IdPersonal in this.selectedMembers) {
      isThereAny = true;
    }
    return isThereAny;
  }
}
