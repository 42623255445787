import { Injectable } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';

@Injectable({
  providedIn: 'root'
})

export class GeolocationService {

  public currentCoordinates: Coordinates;

  constructor(
    private geolocation: Geolocation
  ) {
    this.geolocation.getCurrentPosition().then((resp) => {
      this.currentCoordinates = resp.coords;
    }).catch((error) => {
    });

    let watch = this.geolocation.watchPosition();
    watch.subscribe((data) => {
      this.currentCoordinates = data.coords;
    });
  }

  getLatitude() {
    return this.currentCoordinates.latitude;
  }

  getLongitude() {
    return this.currentCoordinates.longitude;
  }
}